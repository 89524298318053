/* Helper Row Space */
@include helper-row-space(0, 15);

/* Helper Margin */
@include helper-spacing('', 'margin', 'm');
@include helper-spacing('top', 'margin', 'm-t');
@include helper-spacing('right', 'margin', 'm-r');
@include helper-spacing('bottom', 'margin', 'm-b');
@include helper-spacing('left', 'margin', 'm-l');


/* Helper Padding */
@include helper-spacing('', 'padding', 'p');
@include helper-spacing('top', 'padding', 'p-t');
@include helper-spacing('right', 'padding', 'p-r');
@include helper-spacing('bottom', 'padding', 'p-b');
@include helper-spacing('left', 'padding', 'p-l');


/* Helper Font */
@include helper-font-size(8, 80);
@include helper-font-weight(1, 8);


/* Helper Clearfix */
.clearfix { @include clearfix(); }


/* Helper Text */
.text-center { text-align: center !important; }
.text-left { text-align: left !important; }
.text-right { text-align: right !important; }
.text-nowrap { white-space: nowrap !important; }
.text-ellipsis { white-space: nowrap !important; text-overflow: ellipsis; overflow: hidden; }
.text-underline { text-decoration: underline !important; }
.text-gradient { -webkit-background-clip: text !important; -webkit-text-fill-color: transparent !important; }
.line-height-1 { line-height: 1 !important; }
.text-decoration-line-through { text-decoration: line-through !important; }

.row {
	&.row-col-border {
		& > [class^="col-"] {
			& + [class^="col-"] {
				border-left: 1px solid $border-color;
			}
		}
	}
}


/* Helper Float */
.pull-left { float: left !important; }
.pull-right { float: right !important; }
.pull-none { float: none !important; }


/* Helper Border Radius */
.rounded-corner { border-radius: rem(100px) !important; }


/* Helper Table */
.table-valign-middle th, 
.table-valign-middle td,
.table-th-valign-middle th,
.table-td-valign-middle td { vertical-align: middle !important; }
.table-valign-top th, 
.table-valign-top td,
.table-th-valign-top th,
.table-td-valign-top td { vertical-align: top !important; }
.table-valign-bottom th, 
.table-valign-bottom td,
.table-th-valign-bottom th,
.table-td-valign-bottom td { vertical-align: bottom !important; }
.table-th-without-border th { border-left: none !important; border-right: none !important; }
.table-td-without-border td { border: none !important; }
.table-td-bg-animate { @include transition(background .3s linear); }


/* Helper Vertical Align */
.valign-middle { vertical-align: middle !important; }
.valign-top { vertical-align: top !important; }
.valign-bottom { vertical-align: bottom !important; }
.valign-middle-container { display: table; height: 100%; width: 100%; }
.valign-middle-inner-container { display: table-cell; vertical-align: middle; }


/* Helper Size */
@include helper-size('width');
@include helper-size('height');


/* Helper Color */
.loading {
	cursor: progress;
}
.bg-theme {
	background-color: $primary !important;
}
.label-theme {
	background-color: $primary;
}
.text-theme {
	color: $primary;
}
@each $color, $value in $theme-colors {
	@include helper-color($color, $value);
}
@each $color, $value in $colors {
	@include helper-color($color, $value);
}
.no-bg { background: none !important; }
@include background-gradient('bg-gradient-red-pink', $red, $pink);
@include background-gradient('bg-gradient-orange-red', $orange, $red);
@include background-gradient('bg-gradient-yellow-orange', $yellow, $orange);
@include background-gradient('bg-gradient-yellow-red', $yellow, $red);
@include background-gradient('bg-gradient-teal-green', $teal, $green);
@include background-gradient('bg-gradient-yellow-green', $yellow, $green);
@include background-gradient('bg-gradient-blue-purple', $blue, $purple);
@include background-gradient('bg-gradient-cyan-blue', $cyan, $blue);
@include background-gradient('bg-gradient-cyan-purple', $cyan, $purple);
@include background-gradient('bg-gradient-cyan-indigo', $cyan, $indigo);
@include background-gradient('bg-gradient-blue-indigo', $blue, $indigo);
@include background-gradient('bg-gradient-purple-indigo', $purple, $indigo);
@include background-gradient('bg-gradient-silver-black', $silver, $black);


/* Helper Opacity */
@include helper-opacity(0, 10);


/* Helper Box Shadow */
.with-shadow { box-shadow: 0 rem(2px) rem(40px) 0 rgba($black, 0.1) !important; }


/* Helper Display */
.hide { display: none !important; }


/* Helper Image */
.img-responsive { display: block; max-width: 100%; height: auto; }
.img-rounded { border-radius: 0.375rem; }
.img-circle { border-radius: 50%; }


.valign-top { vertical-align: top !important; }
.valign-bottom { vertical-align: bottom !important; }
.valign-middle { vertical-align: middle !important; }
.table-no-border-left {
	& tbody,
	& thead {
		& tr {
			& td,
			& th {
				border-left: none;
			}
		}
	}
}
.table-no-border-right {
	& tbody,
	& thead {
		& tr {
			& td,
			& th {
				border-right: none;
			}
		}
	}
}

.text-gradient {
	-webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}


.row { margin-left: -10px; margin-right: -10px; }
.row > [class^="col-"] {  padding-left: 10px; padding-right: 10px; }
.row.row-space-0 { margin-left: 0; margin-right: 0; }
.row.row-space-2 { margin-left: -1px; margin-right: -1px; }
.row.row-space-4 { margin-left: -2px; margin-right: -2px; }
.row.row-space-6 { margin-left: -3px; margin-right: -3px; }
.row.row-space-8 { margin-left: -4px; margin-right: -4px; }
.row.row-space-10 { margin-left: -5px; margin-right: -5px; }
.row.row-space-12 { margin-left: -6px; margin-right: -6px; }
.row.row-space-14 { margin-left: -7px; margin-right: -7px; }
.row.row-space-16 { margin-left: -8px; margin-right: -8px; }
.row.row-space-18 { margin-left: -9px; margin-right: -9px; }
.row.row-space-20 { margin-left: -10px; margin-right: -10px; }
.row.row-space-22 { margin-left: -11px; margin-right: -11px; }
.row.row-space-24 { margin-left: -12px; margin-right: -12px; }
.row.row-space-26 { margin-left: -13px; margin-right: -13px; }
.row.row-space-28 { margin-left: -14px; margin-right: -14px; }
.row.row-space-30 { margin-left: -15px; margin-right: -15px; }
.row.row-space-0 > [class^="col-"] {  padding-left: 0; padding-right: 0; }
.row.row-space-2 > [class^="col-"] {  padding-left: 1px; padding-right: 1px; }
.row.row-space-4 > [class^="col-"] {  padding-left: 2px; padding-right: 2px; }
.row.row-space-6 > [class^="col-"] {  padding-left: 3px; padding-right: 3px; }
.row.row-space-8 > [class^="col-"] {  padding-left: 4px; padding-right: 4px; }
.row.row-space-10 > [class^="col-"] {  padding-left: 5px; padding-right: 5px; }
.row.row-space-12 > [class^="col-"] {  padding-left: 6px; padding-right: 6px; }
.row.row-space-14 > [class^="col-"] {  padding-left: 7px; padding-right: 7px; }
.row.row-space-16 > [class^="col-"] {  padding-left: 8px; padding-right: 8px; }
.row.row-space-18 > [class^="col-"] {  padding-left: 9px; padding-right: 9px; }
.row.row-space-20 > [class^="col-"] {  padding-left: 10px; padding-right: 10px; }
.row.row-space-22 > [class^="col-"] {  padding-left: 11px; padding-right: 11px; }
.row.row-space-24 > [class^="col-"] {  padding-left: 12px; padding-right: 12px; }
.row.row-space-26 > [class^="col-"] {  padding-left: 13px; padding-right: 13px; }
.row.row-space-28 > [class^="col-"] {  padding-left: 14px; padding-right: 14px; }
.row.row-space-30 > [class^="col-"] {  padding-left: 15px; padding-right: 15px; }

.height-xs { height: 150px !important; }
.height-sm { height: 300px !important; }
.height-md { height: 450px !important; }
.height-lg { height: 600px !important; }
.height-full { height: 100% !important; }
.height-0 { height: 0px !important; }
.height-10 { height: 10px !important; }
.height-20 { height: 20px !important; }
.height-30 { height: 30px !important; }
.height-40 { height: 40px !important; }
.height-50 { height: 50px !important; }
.height-60 { height: 60px !important; }
.height-70 { height: 70px !important; }
.height-80 { height: 80px !important; }
.height-90 { height: 90px !important; }
.height-100 { height: 100px !important; }
.height-150 { height: 150px !important; }
.height-200 { height: 200px !important; }
.height-250 { height: 250px !important; }
.height-300 { height: 300px !important; }
.height-350 { height: 350px !important; }
.height-400 { height: 400px !important; }
.height-450 { height: 450px !important; }
.height-500 { height: 500px !important; }
.height-550 { height: 550px !important; }
.height-600 { height: 600px !important; }

.wrapper { padding: 15px; }

.width-xs { width: 150px !important; }
.width-sm { width: 300px !important; }
.width-md { width: 450px !important; }
.width-lg { width: 600px !important; }
.width-full { width: 100% !important; }
.width-0 { width: 0px !important; }
.width-10 { width: 10px !important; }
.width-20 { width: 20px !important; }
.width-30 { width: 30px !important; }
.width-40 { width: 40px !important; }
.width-50 { width: 50px !important; }
.width-60 { width: 60px !important; }
.width-70 { width: 70px !important; }
.width-80 { width: 80px !important; }
.width-90 { width: 90px !important; }
.width-100 { width: 100px !important; }
.width-150 { width: 150px !important; }
.width-200 { width: 200px !important; }
.width-250 { width: 250px !important; }
.width-300 { width: 300px !important; }
.width-350 { width: 350px !important; }
.width-400 { width: 400px !important; }
.width-450 { width: 450px !important; }
.width-500 { width: 500px !important; }
.width-550 { width: 550px !important; }
.width-600 { width: 600px !important; }

@if $enable-rtl {
	.pull-right {
		float: left !important;
	}
	.pull-left {
		float: right !important;
	}
	.text-left {
		text-align: right !important;
	}
  .text-right {
		text-align: left !important;
	}
	.ml-auto {
		margin-right: auto !important;
		margin-left: 0 !important;
	}
	.mr-auto {
		margin-left: auto !important;
		margin-right: 0 !important;
	}
	$x: 2;
	@for $i from 1 through 5 {
		$x: ($x * 2);
		.mr-#{$i} {
			margin-left: $x * 1px !important;
			
			&:not(.ml-#{$i}) {
				margin-right: 0 !important;
			}
		}
		.mr-n#{$i} {
			margin-left: $x * -1px !important;
			
			&:not(.ml-n#{$i}) {
				margin-right: 0 !important;
			}
		}
		.ml-#{$i} {
			margin-right: $x * 1px !important;
			
			&:not(.mr-#{$i}) {
				margin-left: 0 !important;
			}
		}
		.ml-n#{$i} {
			margin-right: $x * -1px !important;
			
			&:not(.mr-n#{$i}) {
				margin-left: 0 !important;
			}
		}
		.pr-#{$i} {
			padding-left: $x * 1px !important;
			
			&:not(.pl-#{$i}) {
				padding-right: 0 !important;
			}
		}
		.pl-#{$i} {
			padding-right: $x * 1px !important;
			
			&:not(.pl-#{$i}) {
				padding-left: 0 !important;
			}
		}
	}
	$x: 1;
	@for $i from 1 through 30 {
		.m-r-#{$i} {
			margin-left: $i * 1px !important;
			
			&:not(.m-l-#{$i}) {
				margin-right: 0 !important;
			}
		}
		.m-l-#{$i} {
			margin-right: $i * 1px !important;
			
			&:not(.m-r-#{$i}) {
				margin-left: 0 !important;
			}
		}
		.p-r-#{$i} {
			padding-left: $i * 1px !important;
			
			&:not(.p-l-#{$i}) {
				padding-right: 0 !important;
			}
		}
		.p-l-#{$i} {
			padding-right: $i * 1px !important;
			
			&:not(.p-r-#{$i}) {
				padding-left: 0 !important;
			}
		}
	}
}