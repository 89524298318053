.dropdown-menu {
	font-size: rem(12px);
	
	@include box-shadow($dropdown-box-shadow);
	@if $enable-rtl {
		text-align: right;
	}
}
.dropdown-item {
	padding: rem(5px) rem(15px);
	font-weight: 600;
	color: lighten($dark, 15%);
	text-decoration: none;
	
	&:hover,
	&:focus {
		color: $dark;
		background: lighten($silver, 2%);
	}
}