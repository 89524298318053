@import 'node_modules/bootstrap/scss/bootstrap';
@import '~bootstrap/scss/mixins/breakpoints';

.float-grid-parent {
  margin: 0 auto;
}

.float-col {
  padding-left: $grid-gutter-width / 3;
  padding-right: $grid-gutter-width / 3;
}
